import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ThemeContext } from "../Context/Theme/Context";
import ProductGrid from './ProductGrid'


import Wrapper from "./Wrapper";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import productDetails from "./ProductDetails";
import { Button, NoDataFound, SideDrawer, Slider } from "../Library/Module";
import { getCollection } from "../servies/services";
import { objectToQueryString } from "../Library/Utility/Utility";

interface HomeProps {
    link: string;
}

const ProductDetails = ({ link }: HomeProps): JSX.Element => {
    const { orgUser, updateBgColor, breadcrumb, updateBreadcrumb, catlogData } = useContext(ThemeContext)
    const { category, product_title } = useParams()
    const navigate = useNavigate()
    const [open, setOpen] = useState<any>(false)
    const [data, setData] = useState<any>([])
    const [selectedData, setSelectedData] = useState<any>('')
    const [bgImage, setBgImage] = useState<any>('')

    useEffect(() => {
        const data = productDetails?.filter((item) => {
            return item?.product_name === product_title
        })
        setSelectedData(data[0])
    }, [product_title])

    const selectedProduct = useCallback((data) => {
        setSelectedData(data)
        setOpen(true)
    }, [])

    const CloseDrawer = () => {
        setOpen(false)
    };

    const applyBtn = useCallback((data) => {
        setBgImage(data?.image)
        updateBgColor?.(data?.image)
    }, [updateBgColor])

    const closepopup = useCallback(() => {
        setSelectedData('')
    }, [])

    useEffect(() => {
        const data = [
            { "name": "Home", "link": "/", "icon": "" },
            { "name": category === "drappery" ? 'Drapery' : category, "link": `/category/${category}`, "icon": "" },
            { "name": product_title, "link": `/category/${category}/${product_title}`, "icon": "" }

        ]
        updateBreadcrumb(data)
    }, [category, product_title, updateBreadcrumb])

    const catagoryData = useMemo(() => {
        if (catlogData?.length !== 0) {
            return catlogData?.find((item: any) => {
                return item?.slug === product_title
            })
        }
    }, [catlogData, product_title])

    const [collectionData, setCollectionData] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(true)
        const getData = async () => {
            const query = {
                // "category": category,
                "catalog": product_title,
                // "sortBy": "slug"
            }
            const APIResponse = await getCollection(objectToQueryString(query));
            if (APIResponse?.data?.isSuccess === true) {
                if (APIResponse?.data?.data) {
                    // console.log("APIResponse?.data?.data?.sort((a: any, b: any) => a?.title - b?.title)", APIResponse?.data?.data?.reverse()
                    setCollectionData(APIResponse?.data?.data?.sort((a: any, b: any) => a?.order_id - b?.order_id))
                }
            } else {

                console.error("something went wrong, please try after sometime.")
            }

        };
        getData();
        setIsLoading(false)
    }, [category, product_title])

    return (
        <>
            <div className={`banner banner2 ${category}`}>
                <div className="banner_text">
                    <h3 className="changeCase">{category === "drappery" ? 'Drapery' : category} / {product_title}</h3>
                </div>
            </div>
            <div className="">
                {breadcrumb}
            </div>
            <div className="container pt-4 pb-4">
                <div className="row">
                    <ProductGrid rowClass={'col-md-12 col-sm-12 mb-4'} hideCollection={false} showStockBtn={true} selectedProduct={selectedProduct} showDescription={true} data={catagoryData}></ProductGrid>
                    <div className="col-md-12 col-sm-12 mb-4">
                        {!isLoading && collectionData?.length === 0 && <NoDataFound
                            primaryText="Collection not available"
                            secondaryText="Please contact system administrator"></NoDataFound>}
                        {collectionData?.length > 0 &&
                            <div className="description">
                                <h3>Collections</h3>
                                <ul className="collection_list">
                                    <Slider title={product_title} category={category} showFullImage={false} images={collectionData}></Slider>
                                    <div className="clearfix"></div>
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
        </>
    )
}

export default ProductDetails