import axios from 'axios';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { createBrowserHistory } from "history";
import { cacheAdapterEnhancer } from 'axios-extensions';

const user_details = localStorage.getItem('user_details');
const user_details_parse = JSON.parse(user_details)
const token = user_details_parse?.token
const historyConfig = {
    basename: 'tatva-admin/'
};

export const history = createBrowserHistory(historyConfig);

export let site_data = {
    "site_name": "CMS",
    "site_title": "",
    "welcome_back": "Welcome Back !",
    "login_note": "Sign in to continue to Admin Panel"

    //  {site_data.site_name} 
}

export const API = axios.create({
    baseURL: `/`,
    headers: { 'Cache-Control': 'no-cache' },
    // disable the default cache and set the cache flag
    adapter: cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: false, cacheFlag: 'useCache' })

});

export const setUpAxios = async () => {
    return (
        API.interceptors.response.use(response => {
            console.log("response", response)
            return response;
        }, error => {
            console.log("error", JSON.stringify(error));
            console.log("message aixos", error.response, error.message)
            if (!error.response) {
                alert("Server is not running")
                console.log("Interceptor - Server is not running");

            } else if (error.response.status === 401) {

                console.log("Interceptor - 401 - Unauthorized: Token Invalid, please login again");

            } else if (error.response.status === 400) {

                console.log("Interceptor - 400" + error.response.data.messages);
                return error.response;

            } else if (error.response.status === 404) {
                alert("API not Found")
                console.log("API not Found");
                return null;

            } else {

                const { config} = error;
                if (!config || !config.retry) {
                    return Promise.reject(error);
                }

                // retry while Network timeout or Network Error
                // if (!(message.includes("timeout") || message.includes("Network Error"))) {
                //     return Promise.reject(error);
                // }
                config.retry -= 1;
                const delayRetryRequest = new Promise((resolve) => {
                    setTimeout(() => {
                        console.log("retry the request", config.url);
                        resolve();
                    }, config.retryDelay || 1000);
                });
                return delayRetryRequest.then(() => axios(config));
            }
        })
    )

}


export const GetCategory = async (data, useCache) => {
    console.log("service CustomTypeById", data)
    let url = data ? process.env.react_app_base_url + `/api/v1/product/category?${data}` : process.env.react_app_base_url + `/api/v1/product/category`
    const response = await API.get(url, {
        retry: 5, retryDelay: 500,
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const getCatlog = async (data, useCache) => {
    console.log("service CustomTypeById", data)
    let url = data ? process.env.react_app_base_url + `/api/v1/product/catalog?${data}` : process.env.react_app_base_url + `/api/v1/product/catalog`
    const response = await API.get(url, {
        retry: 5, retryDelay: 500,
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const getCollection = async (data, useCache) => {
    console.log("service CustomTypeById", data)
    let url = data ? process.env.react_app_base_url + `/api/v1/product/collection?${data}` : process.env.react_app_base_url + `/api/v1/product/collection`
    const response = await API.get(url, {
        retry: 5, retryDelay: 500,
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};

export const EnquiryDetails = async (data, useCache) => {
    console.log("service CustomTypeById", data)
    const response = await API.post(process.env.react_app_base_url + '/api/v1/enquiry', data, {
        headers: {
            "auth-token": `${token}`,
        }
    })
    return response;
};