import { NavLink } from 'react-router-dom';
import Logo from '../images/logo.png'
import { useContext } from 'react';
import { ThemeContext } from '../Context/Theme/Context';
const Header = () => {
  const { mainCategory } = useContext(ThemeContext)
  return (
    <div className="header">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-sm-12">
            <NavLink to={'/'}><img src={Logo} title="" alt='' /></NavLink>
          </div>
          <div className="col-md-9 col-sm-12 text-right ">
            <div className="top_nav">
              <ul>
                <li>
                  <a target="_blank" rel="noreferrer" href="http://customers.dynamicfabrics.net/">customer login</a>
                </li>
                <li>
                  <a className='alternative_btn' target="_blank" rel="noreferrer" href="http://customers.dynamicfabrics.net/">Make a Payment</a>
                </li>
              </ul>
            </div>
            <div className="main_nav">
              <ul>
                {mainCategory?.map((menu: any) => {
                  return <>
                    <li>
                      <NavLink to={`category/${menu?.slug}`}>{menu?.title === "Drappery" ? 'Drapery' : menu?.title}</NavLink>
                    </li>
                  </>
                })}
                <li>
                  <NavLink to={'/about-us'}>About Us</NavLink>
                </li>
                <li>
                  <NavLink to={'/contact-us'}>Contact Us</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
